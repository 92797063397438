import boy from '../icons/boy.png';
import girl from '../icons/girl.png';
import Card from "../models/card.model";

const cards: Card[] = [
  {
    id: 0,
    title: "Melody Ge",
    description:
      "I’m Melody Ge and am 9 years old, in 3rd grade. I like reading (a lot) and drawing. I play piano and have played about 18 songs. I have took an Minecraft coding class before and other stuff like that. ",
    imageUrl:
      "https://lh3.googleusercontent.com/-DjmOWxrHqxA/YEQwO7IvpNI/AAAAAAAADLc/HcY7Hts6KNso-GfLESXz0GkL1xrEAu3pACK8BGAsYHg/s512/A9EDCC69-C285-400D-8DFC-165A282AA2F9.jpeg",
    contentUrl: "https://intlcea.org/volunteer/melody/",
  },
  {
    id: 1,
    title: "Kevin Zhang",
    description:
      "Hello, My name is Kevin, and I am 10 years old. I live in Seattle, Washington State. Some of the things I like to do in school are Writing, Science and Math. Some of the things I like to do outside school are Math, Reading, Writing and Music.",
    imageUrl:
      "https://lh3.googleusercontent.com/pw/ACtC-3cPwKXP9OJd9ktEQjGtIQKXO9-myMXtNB4Z0SglgnT3EAYnJP8uq5LUGbVrD4Wzrpmj8bLhEH9nqEkjrOyOmaUDTvBVZm5gl0hHEFlTR2m0BmPn-BjnTlsp-MLLZCjRVX1JY4innK5nz6x76idM3ZM=w664-h947-no?authuser=0",
    contentUrl: "https://intlcea.org/volunteer/kevin/",
  },
  {
    id: 2,
    title: "Annabel Xu",
    description:
      "Hi, I am Annabel, nice to meet you! My hobbies are:writing, reading, drawing, sewing, ice skating, coloring,and playing prodigy.",
    imageUrl: "https://i.postimg.cc/Jh13SCxB/Screen-Shot-2021-03-29-at-10-06-04-PM.png",
    contentUrl: "https://intlcea.org/volunteer/annabel/",
  },
  {
    id: 3,
    title: "Zimo Ge",
    description:
      "Hi, I am Zimo, nice to meet you! I am a second grader at Clover Ave. Elementary. I am very intrested in science. For example: space, geometry, engineering, history, fiction, and animals.",
    imageUrl: girl,
    contentUrl: "https://intlcea.org/volunteer/zimo/",
  },
  {
    id: 4,
    title: "Felix Guo",
    description:
      "I am a 4th grader that loves to play chess. My school is Broadway Elementary. I live in Los Angeles, California.",
    imageUrl: "https://intlcea.org/wp-content/uploads/2020/04/236732264-1-e1587628342610-813x1024.jpg",
    contentUrl: "https://intlcea.org/volunteer/felix/",
  },
  {
    id: 5,
    title: "Grace Ge",
    description:
      "Hello! My name is Grace, and I'm 11 years old. I'm a student of Warner Elementary School. In my freetime, I like to read, write, and play with my sister. I'm also in a volleyball club called San Gabriel Elite and is in a team called 12 Rosh.",
    imageUrl: "https://lh3.googleusercontent.com/-WQLahHI1Pv4/YIS5DFqclPI/AAAAAAAADfE/PSlbvLNV_lEiSkfhZvBnKTz4yL4YXRk5wCK8BGAsYHg/s0/FD323B56-3B92-4CBB-AFA8-FC2A3D9BDFBE.jpeg?authuser=0",
    contentUrl: "https://intlcea.org/volunteer/grace/",
  },
  {
    id: 6,
    title: "Stephen Chen",
    description:
      "Hello, my name is Stephen, I live in Chapel Hill, North Carolina. I am 15 years old, I am a freshman at East Chapel Hill High School. I like coding, doing math, and studying science in my free time.",
    imageUrl: "http://intlcea.org/volunteer/stephen/images/profile5.jpg",
    contentUrl: "https://intlcea.org/volunteer/stephen/",
  },
  {
    id: 7,
    title: "<<Your Name>>",
    description:
      "Guess who I am",
    imageUrl: girl,
    contentUrl: "",
  },
  {
    id: 8,
    title: "<<Your Name>>",
    description:
      "Guess who I am",
    imageUrl: boy,
    contentUrl: "",
  },
];

cards.forEach((card) => {
  card.achievements = ["Club Member"];
});

export default cards;
