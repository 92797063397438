import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import Menu from "./components/Menu";
import Members from "./pages/Members";
import { RootState } from "./store";
/* Theme variables */
import "./theme/variables.css";

const App: React.FC = () => {
  const routes = useSelector((state: RootState) => state.routes);

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Redirect exact from="/" to="/page/members" />
            <Route path="/page/members" exact={true}>
              <Members />
            </Route>
            {routes.map((route) => {
              return (
                <Route
                  key={route.url}
                  path={route.url}
                  exact={true}
                  component={route.page}
                ></Route>
              );
            })}
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
