import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote
} from "@ionic/react";
import {
  bookmarkOutline
} from "ionicons/icons";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppPage } from "../reducers/routes";
import { RootState } from "../store";
import "./Menu.css";

const labels: String[] = [
  "Club Manager:",
  "President:",
  "Group Email: cc@icea1.org",
];

const Menu: React.FC = () => {
  const location = useLocation();
  const appPages = useSelector((state: RootState) => state.routes);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>ICEA Coding Club</IonListHeader>
          <IonNote></IonNote>
          {appPages.map((appPage: AppPage, index: number) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonList id="labels-list">
          <IonListHeader>Contact</IonListHeader>
          {labels.map((label, index) => (
            <IonItem lines="none" key={index}>
              <IonIcon slot="start" icon={bookmarkOutline} />
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
