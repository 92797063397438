import {
  IonButtons,

  IonContent,

  IonHeader,
  IonMenuButton,
  IonPage,

  IonTitle,
  IonToolbar
} from "@ionic/react";
import React from "react";

const Page: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>In Development</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>In Development</IonContent>
    </IonPage>
  );
};

export default Page;
