import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonImg
} from "@ionic/react";
import React from "react";
import { useHistory, useLocation } from "react-router";
import Card from "../data/models/card.model";

const UserCard: React.FC<Card> = ({
  id,
  title,
  description,
  achievements,
  imageUrl,
  contentUrl,
}) => {
  const history = useHistory();
  const loc = useLocation();

  return (
    <IonCard style={{ width: "300px" }} class="ion-padding-bottom">
      <IonImg className="ion-activatable ripple-parent" src={imageUrl} />
      <IonCardHeader>
        {achievements
          ? achievements.map((achievement) => (
            <IonCardSubtitle>{achievement}</IonCardSubtitle>
          ))
          : ""}
        <IonCardTitle>{title}</IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        {description}

        <IonButton
          class="ion-padding"
          style={{ margin: "auto" }}
          onClick={() => contentUrl ? window.open(contentUrl) : alert("Finish Your Homework!") }
        >
          {contentUrl ? "Learn More" : "Add Me"}
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default UserCard;
