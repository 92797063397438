import members from "../data/members/cards";
import Card from "../data/models/card.model";

const initialState: Card[] = members;

export const NEW_USER = "NEW_USER";
export const RANDOM_ORDER = "RANDOM_ORDER";

export default (
  state = initialState,
  { type, payload }: { type: string; payload?: Card }
) => {
  switch (type) {
    case NEW_USER:
      return { ...state, ...payload };

    default:
      return state;
  }
};
