import {
  peopleOutline,
  peopleSharp,
  hammerOutline,
  hammerSharp,
  calendarOutline,
  calendarSharp,
} from "ionicons/icons";
import React from "react";
import Empty from "../pages/Empty";
import Member from "../pages/Members";

export interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  subtitle: string;
  page: React.FC;
}

const appPages: AppPage[] = [
  {
    title: "Club Members",
    subtitle: "ICEA Coding Club Members",
    url: "/page/members",
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
    page: Member,
  },
  {
    title: "Club Projects",
    subtitle: "ICEA Coding Club Projects",
    url: "/page/projects",
    iosIcon: hammerOutline,
    mdIcon: hammerSharp,
    page: Empty,
  },
  {
    title: "Club Events",
    subtitle: "ICEA Coding Club Events",
    url: "/page/events",
    iosIcon: calendarOutline,
    mdIcon: calendarSharp,
    page: Empty,
  },
];

const initialState = appPages;

interface ActionType {
  type: string;
  payload?: {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
    subtitle: string;
    page: React.FC;
  };
}

export default (state = initialState, { type, payload }: ActionType) => {
  switch (type) {
    case "NEW_ROUTE":
      return { ...state, ...payload };
    default:
      return state;
  }
};
